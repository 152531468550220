.accordeon {
  // margin-bottom: -10px;
}
.accordeon__item {
  overflow: hidden;
  margin-bottom: 25px;
  transition: .5s;
  border-radius: 7px;
  &.active {
    box-shadow: 0 0 50px -10px rgba(0,98,113,.65);
  }
  &:hover {
    transition: .5s;
    box-shadow: 0 0 50px -10px rgba(0,98,113,.65);
  }
}
.accordeon-title {
  position: relative;
  margin: 0;
  padding: 20px 70px 17px 30px;
  cursor: pointer;
  transition: .5s;
  border-radius: 6px;
  background-color: #19aec1;

  @include m500() {
    padding: 20px 70px 17px 15px;
  }
  @include m425() {
    padding: 15px 45px 13px 15px;
  }
  &:hover {
    transition: .5s;
    background-color: #00869a;
    &::before,
    &::after {
      transition: .5s;
      background-color: #e6af3e;
    }
  }
  &::before,
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
    transition: .1s;
    pointer-events: none;
    border-radius: 3px;
    background-color: #fff;
  }
  &::before {
    right: 30px;
    width: 30px;
    height: 6px;

    @include m425() {
      right: 15px;
      width: 21px;
      height: 4px;
    }
  }
  &::after {
    right: 42px;
    width: 6px;
    height: 30px;
    opacity: 1;

    @include m425() {
      right: 24px;
      width: 4px;
      height: 20px;
    }
  }
  &.is-opened {
    background-color: transparent;
    background-image: url('../img/bg-green.jpg');
    &::after {
      transition: .1s;
      opacity: 0;
    }
    .accordeon-title__link {
      color: #fff;
    }
  }
}

.accordeon-content {
  display: none;
  padding: 20px 20px 10px 20px;
}
.accordeon-content__title {
  font-size: 24px;
  margin-bottom: 15px;
  color: #006271;

  @include pt(regular);
}
.accordeon-content__subtitle {
  font-size: 16px;
  color: #585858;

  @include os(semibold);
}
.accordeon-content__text {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
  color: #585858;

  @include os(regular);
}
.accordeon-content__pic-list {
  display: flex;
  flex-direction: row;
  margin-bottom: -20px;

  justify-content: space-between;
  flex-wrap: wrap;
}
.accordeon-content__pic {
  overflow: hidden;
  width: 23.5%;
  max-height: 180px;
  margin-bottom: 20px;
  img {
    display: block;
    width: 100%;
  }
}

.accordeon-title__click {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.accordeon-title__link {
  font-size: 18px;
  line-height: 1;
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  pointer-events: none;

  @include pt(bold);
  @include m680() {
    // font-size: 18px;
  }
  @include m500() {
    // font-size: 16px;
  }
  &:hover {
    color: #fff;
  }
}
