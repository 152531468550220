.top-slider {
  position: relative;
  //общие стили для верхнего слайдера
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
  }
  .bx-wrapper {
    min-height: 300px;
    margin: 0 auto;
    border: none;
    background-color: transparent;
    box-shadow: none;
    .bx-viewport {
      min-height: 300px;
    }
    img {
      max-width: 9999px;
      height: 100%;
    }
  }
  .bx-controls-direction {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    max-width: 1100px;
    margin: auto;

    @include m500() {
      display: none;
    }
  }
  .bx-wrapper .bx-controls-direction a {
    width: 25px;
    height: 26px;
  }
  .bx-wrapper .bx-prev {
    visibility: visible;
    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      content: '';
      opacity: 0;
    }
    &::before {
      transition: .5s;
      opacity: 1;
      background: url('../img/bx/top-slider-arr.png') 0 0 no-repeat;
    }
    &::after {
      transition: .5s;
      opacity: 0;
      background: url('../img/bx/top-slider-arr.png') 0 100% no-repeat;
    }
    &:hover {
      &::before {
        transition: .5s;
        opacity: 0;
      }
      &::after {
        transition: .5s;
        opacity: 1;
      }
    }
  }
  .bx-wrapper .bx-next {
    visibility: visible;
    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      content: '';
    }
    &::before {
      transition: .5s;
      opacity: 1;
      background: url('../img/bx/top-slider-arr.png') 100% 0 no-repeat;
    }
    &::after {
      transition: .5s;
      opacity: 0;
      background: url('../img/bx/top-slider-arr.png') 100% 100% no-repeat;
    }
    &:hover {
      &::before {
        transition: .5s;
        opacity: 0;
      }
      &:after {
        transition: .5s;
        opacity: 1;
      }
    }
  }
}
.top-slider__item {
  max-height: 400px;
}

.top-slider__banner--wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 700px;
  margin: auto;
  text-align: center;
  &::before {
    display: inline-block;
    height: 100%;
    margin-right: -4px;
    content: '';
    vertical-align: middle;
  }
}
.top-slider__banner {
  position: relative;
  z-index: 10000;
  display: inline-block;
  margin-right: -4px;
  vertical-align: middle;

  @include m768() {
    padding: 10px 40px;
  }
  @include m500() {
    padding: 10px;
  }
}
.top-slider__title {
  font-size: 48px;
  line-height: 48px;
  position: relative;
  margin-bottom: 50px;
  color: #fff;

  @include os(light);
  @include m768() {
    font-size: 34px;
    line-height: 34px;
  }
  @include m680() {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 25px;
  }
  &::before {
    position: absolute;
    right: 0;
    bottom: -30px;
    left: 0;
    display: block;
    width: 70%;
    max-width: 440px;
    height: 3px;
    margin: auto;
    content: '';
    background-color: #19aec1;

    @include m680() {
      bottom: -15px;
      width: 40%;
      height: 1px;
    }
  }
}
.top-slider__text {
  font-size: 16px;
  line-height: 18px;
  color: #fff;

  @include pt(regular);
  @include m768() {
    font-size: 16px;
  }
}
