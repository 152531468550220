*,
*::before,
*::after {
  box-sizing: inherit;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
html {
  font-size: 14px;
  line-height: 1.2;
  box-sizing: border-box;

  @include pt(regular);
}
body {
  // display: flex;
  // flex-direction: column;
  min-width: 320px;
  height: 100%;
  color: #3f3f3f;
  background: transparent url('../img/bg-white.jpg') 0 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0;
}
.wrapper {
  display: table;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.content {
  display: table-row;
  // flex: 1 0 auto;
  height: 100%;
}
.container-lg,
.container-sm {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.container-lg {
  padding: 0 10px;
}
.container-sm {
  padding: 0 30px;

  @include m768() {
    padding: 0 20px;
  }
  @include m425() {
    padding: 0 15px;
  }
}
.fix {
  // overflow: hidden;
}
.header,
.footer {
  // flex: 0 0 auto;
  display: block;
  margin: 0;
}

//lightbox styles
body:after {
  content: url('../img/lb/close.png') url('../img/lb/loading.gif') url('../img/lb/prev.png') url('../img/lb/next.png');
}
.lb-cancel {
  background: url('../img/lb/loading.gif') no-repeat;
}
.lb-nav a.lb-prev {
  background: url('../img/lb/prev.png') left 48% no-repeat;
}
.lb-nav a.lb-next {
  background: url('../img/lb/next.png') right 48% no-repeat;
}
.lb-data .lb-close {
  background: url('../img/lb/close.png') top right no-repeat;
}
.disableScrolling {
  z-index: 10000;
}
