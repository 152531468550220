.partners__header {
  margin-bottom: 20px;
  text-align: center;
}
.partners__title {
  font-size: 30px;
  position: relative;
  display: inline;
  padding-left: 35px;
  color: #363636;

  @include pt(regular);
  @include m425() {
    font-size: 26px;
  }
  &::before {
    position: absolute;
    top: 7px;
    left: 0;
    width: 25px;
    height: 25px;
    content: '';
    background: transparent url('../img/title-icon-sprite.png') 100% 0 no-repeat;

    @include m425() {
      top: 3px;
    }
  }
}

.partners-slider {
  position: relative;
  width: 220px;
  margin: 0 auto;
  padding: 10px 0;
  border: 1px dashed #efc051;
  border-radius: 4px;

  @include mf560() {
    width: 440px;
  }
  @include mf768() {
    width: 660px;
  }
  @include mf975() {
    width: 880px;
  }
  @include mf1200() {
    width: 1100px;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
  }
  .bx-wrapper {
    margin: 0 auto;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
  .bx-controls-direction {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    max-width: 1100px;
    margin: auto;
  }
  .bx-wrapper .bx-controls-direction a {
    width: 25px;
    height: 26px;
  }
  .bx-wrapper .bx-prev {
    left: -40px;
    visibility: visible;
    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      content: '';
      opacity: 0;
    }
    &::before {
      transition: .5s;
      opacity: 1;
      background: url('../img/bx/top-slider-arr.png') 0 100% no-repeat;
    }
    &::after {
      transition: .5s;
      opacity: 0;
      background: url('../img/bx/top-slider-arr.png') 0 100% no-repeat;
    }
    &:hover {
      &::before {
        transition: .5s;
        opacity: 0;
      }
      &::after {
        transition: .5s;
        opacity: 1;
      }
    }
  }
  .bx-wrapper .bx-next {
    right: -40px;
    visibility: visible;
    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      content: '';
    }
    &::before {
      transition: .5s;
      opacity: 1;
      background: url('../img/bx/top-slider-arr.png') 100% 100% no-repeat;
    }
    &::after {
      transition: .5s;
      opacity: 0;
      background: url('../img/bx/top-slider-arr.png') 100% 100% no-repeat;
    }
    &:hover {
      &::before {
        transition: .5s;
        opacity: 0;
      }
      &:after {
        transition: .5s;
        opacity: 1;
      }
    }
  }
}
.partners-slider__item {
  img {
    display: block;
    margin: 0 auto;
  }
}
