.catalog-one-slider {
  width: 50%;
  padding-right: 20px;
  @include m680() {
    width: 100%;
    padding: 0;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 0;
  }
  .bx-wrapper {
    border: none;
    box-shadow: none;
    margin: 0 0 10px 0;
  }
}
.catalog-one-slider__pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  span {
  position: relative;
    width: 100%;
    display: block;
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      content: '';
      transition: all .5s;
      border: 2px solid transparent;

    }
  }
  a {
    width: 23.5%;
    margin-bottom: 10px;
    &.active span::before,
    &:hover span::before {
      transition: all .5s;
      border: 2px solid #19aec1;
    }
    img {
      width: 100%;
      display: block;
    }
  }
}
