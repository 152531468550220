.solution-sec {
  text-align: center;
  background: url('../img/bg-solution.jpg') 50% 50%/cover;
  .container-lg {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
.solution-sec__title {
  font-size: 30px;
  margin-bottom: 30px;
  color: #fff;

  @include pt(regular);
}
.solution-sec__text {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 70px;
  color: #fff;

  @include pt(regular);
}
.solution-sec__item {
  position: relative;
  display: inline-block;
  width: 20%;
  margin-right: -4px;
  vertical-align: top;
  @include m1000() {
    width: 33.3%;
    max-width: 250px;
    margin-bottom: 30px;
  }
  @include m680() {
    width: 50%;
  }
  &:hover {
    .solution-sec__icon {
      transition: .5s;
      border: 2px solid #f0b132;
    }
    .solution-sec__svg {
      fill: #f0b132;
      transition: .5s;
    }
    .solution-sec__icon-text {
      transition: .5s;
      color: #f0b132;
    }
  }
}
.solution-sec__icon {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  transition: .5s;
  border: 2px solid #fff;
  border-radius: 4px;
}
.solution-sec__svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 65px;
  max-height: 65px;
  margin: auto;
  transition: .5s;

  fill: #fff;
}
.solution-sec__icon-text {
  font-size: 18px;
  transition: .5s;
  color: #fff;

  @include pt(bold);
}
.solution-sec__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
