.footer {
  position: relative;
  margin-top: 10px;
  padding-bottom: 10px;
  &::before {
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: url('../img/bg-green.jpg') 0 0;
  }
  .container-lg {
    position: relative;
    z-index: 10;
    overflow: hidden;
    padding: 10px;
    border-radius: 5px;

    @include m1000() {
      text-align: center;
    }
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      opacity: .9;
      background-image: linear-gradient(to top, #005f72 0%, #13a7b9 100%);
    }
  }
}
.footer-logo,
.footer-btn,
.footer-contacts {
  position: relative;
  display: inline-block;
  margin-right: -4px;
  vertical-align: middle;
}
.footer-logo {
  font-size: 12px;
  width: 20%;
  padding-left: 20px;
  text-align: left;
  color: #fff;

  @include m1000() {
    width: 30%;
    margin-bottom: 10px;
  }
  @include m768() {
    width: 40%;
  }
  @include m768() {
    display: block;
    width: 300px;
    margin: 10px auto;
    padding: 0;
    text-align: center;
    .footer-logo__copyright {
      display: none;
    }
  }
  img {
    width: 100%;
    max-width: 160px;
  }
}
.footer-btn {
  width: 25%;
  text-align: center;

  @include m1000() {
    width: 30%;
    margin-bottom: 10px;
  }
  @include m768() {
    width: 40%;
  }
  @include m680() {
    display: block;
    width: 300px;
    margin: 0 auto 10px;
    text-align: center;
  }
}
.footer-contacts {
  width: 55%;
  text-align: left;

  @include m1000() {
    display: block;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
  }
}

.footer-contacts__adress,
.footer-contacts__phone,
.footer-contacts__mail {
  position: relative;
  display: inline-block;
  margin-right: -4px;
  vertical-align: top;

  @include m680() {
    &:before {
      display: none;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    left: -20px;
    width: 1px;
    height: 60px;
    content: '';
    background-color: #004c5b;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
}
.footer-contacts__adress {
  width: 38%;

  @include m680() {
    display: block;
    width: 300px;
    margin: 0 auto 10px;
    text-align: center;
  }
}
.footer-contacts__phone {
  width: 34%;

  @include m680() {
    display: block;
    width: 300px;
    margin: 0 auto 10px;
    text-align: center;
  }
}
.footer-contacts__mail {
  width: 28%;

  @include m680() {
    display: block;
    width: 300px;
    margin: 0 auto 10px;
    text-align: center;
  }
}
.footer-contacts__title {
  font-size: 18px;
  margin-bottom: 5px;
  color: #004c5b;

  @include os(bold);
}
.footer-contacts__link {
  font-size: 16px;
  line-height: 18px;
  transition: .5s;
  text-decoration: none;
  color: #fff;
  border-bottom: 1px solid transparent;
  &:hover {
    transition: .5s;
    border-bottom: 1px solid #fff;
  }
}
