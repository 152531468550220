.news-sec {
  text-align: center;
  .container-lg {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
.news-sec__left,
.news-sec__right {
  display: inline-block;
  margin-right: -4px;
  vertical-align: top;
}

.news-sec__left {
  width: 66.6%;
  border: 1px dashed transparent;

  @include m1000() {
    width: 100%;
    max-width: 700px;
  }
  .news-sec-item {
    width: 50%;
    padding: 10px 37px 10px 0;

    @include m680() {
      width: 100%;
      padding: 10px 20px 10px 17px;
    }
    .news-sec-item__pic {
      @include m680() {
        display: inline-block;
        width: 30%;
        margin-right: -4px;
        vertical-align: top;
      }
      @include m500() {
        display: none;
      }
    }
    .news-sec-item__descr {
      @include m680() {
        display: inline-block;
        width: 70%;
        margin-right: -4px;
        padding-left: 30px;
        vertical-align: top;
      }
      @include m500() {
        width: 100%;
        padding-left: 0;
      }
    }
  }
  .news-sec-item__header {
    padding: 10px 10px 0 0;

    @include m680() {
      padding-left: 20px;
    }
  }
}
.news-sec__right {
  width: 33.3%;
  border: 1px dashed #efc051;
  border-radius: 4px;

  @include m1000() {
    width: 100%;
    max-width: 700px;
  }
  .news-sec-item {
    padding: 10px 20px;
  }
  .news-sec-item__header {
    padding: 10px 10px 0 20px;
  }
  .news-sec-item__pic {
    @include m1000() {
      display: inline-block;
      width: 30%;
      margin-right: -4px;
      vertical-align: top;
    }
    @include m500() {
      display: none;
    }
  }
  .news-sec-item__descr {
    @include m1000() {
      display: inline-block;
      width: 70%;
      margin-right: -4px;
      padding-left: 30px;
      vertical-align: top;
    }
    @include m500() {
      width: 100%;
      padding-left: 0;
    }
  }
}

.news-sec-item {
  position: relative;
  display: inline-block;
  margin-right: -4px;
  transition: .5s;
  text-align: left;
  vertical-align: top;
  border: 1px dashed transparent;
  &:hover {
    .news-sec-item__title {
      transition: .5s;
      color: #f0b132;
    }
    .news-sec-item__date {
      &::before {
        transition: .5s;
        background-color: #f0b132;
      }
    }
  }
}
.news-sec-item__header {
  margin-bottom: 5px;
  text-align: left;
}
.news-sec-item__header-title {
  font-size: 30px;
  position: relative;
  padding-left: 35px;
  color: #363636;

  @include pt(regular);
  @include m425() {
    font-size: 26px;
  }
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 25px;
    height: 25px;
    margin: auto;
    content: '';
    background: transparent url('../img/title-icon-sprite.png') 0 0 no-repeat;
  }
}
.news-sec-item__pic {
  overflow: hidden;
  max-height: 230px;
  margin-bottom: 13px;
  border-radius: 5px;
  img {
    display: block;
    width: 100%;
  }
}
.news-sec-item__title {
  font-size: 18px;
  display: block;
  margin-bottom: 3px;
  transition: .5s;
  color: #363636;

  @include pt(bold);
}
.news-sec-item__date {
  font-size: 12px;
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-left: 10px;
  color: #363636;

  @include pt(regular);
  &::before {
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 130px;
    height: 2px;
    content: '';
    transition: .5s;
    background-color: #19aec1;
  }
}
.news-sec-item__text {
  font-size: 12px;
  line-height: 14px;
  display: block;
  margin-bottom: 10px;
  color: #363636;

  @include pt(regular);
}
.news-sec-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
