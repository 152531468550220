@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans/OpenSans.eot');
  src: url('../fonts/OpenSans/OpenSans.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../fonts/OpenSans-Light/OpenSans-Light.eot');
  src: url('../fonts/OpenSans-Light/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSans-Light/OpenSans-Light.ttf') format('truetype'),
  url('../fonts/OpenSans-Light/OpenSans-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans-Bold/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold/OpenSans-Bold.woff') format('woff'),
  url('../fonts/OpenSans-Bold/OpenSans-Bold.ttf') format('truetype'),
  url('../fonts/OpenSans-Bold/OpenSans-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Semibold';
  src: url('../fonts/OpenSans-Semibold/OpenSans-Semibold.eot');
  src: url('../fonts/OpenSans-Semibold/OpenSans-Semibold.woff') format('woff'),
  url('../fonts/OpenSans-Semibold/OpenSans-Semibold.ttf') format('truetype'),
  url('../fonts/OpenSans-Semibold/OpenSans-Semibold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'PTSans-Regular';
  src: url('../fonts/PTSans-Regular/PTSans-Regular.eot');
  src: url('../fonts/PTSans-Regular/PTSans-Regular.woff') format('woff'),
  url('../fonts/PTSans-Regular/PTSans-Regular.ttf') format('truetype'),
  url('../fonts/PTSans-Regular/PTSans-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PTSans-Bold';
  src: url('../fonts/PTSans-Bold/PTSans-Bold.eot');
  src: url('../fonts/PTSans-Bold/PTSans-Bold.woff') format('woff'),
  url('../fonts/PTSans-Bold/PTSans-Bold.ttf') format('truetype'),
  url('../fonts/PTSans-Bold/PTSans-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}