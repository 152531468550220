.btn-mail__wrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 5px 13px 0 rgba(0,0,0,.3);
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -5px;
    left: 0;
    content: '';
    transition: .5s;
    background-color: #ebb741;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    margin: auto;
    content: '';
    background-color: #dcdcdc;
  }
  &:hover {
    .btn-mail {
      transition: .5s;
      background-color: #ee7b4d;
      &::after {
        background-position: -69px 15px;
      }
      svg {
        transition: .5s;

        fill: #ef835a;
      }
    }
    &::before {
      transition: .5s;
      background-color: #eb7041;
    }
  }
}
.btn-mail {
  font-size: 16px;
  position: relative;
  z-index: 10;
  display: inline-block;
  overflow: hidden;
  padding: 15px 65px 10px 15px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  border-radius: 4px;
  background-color: #eebf4d;
  text-shadow: 1px 1px 2px rgba(100, 100, 100, 1);

  @include pt(bold);
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    margin: auto;
    content: '';
    background: #f8f8f8;
  }
  svg {
    position: absolute;
    z-index: 10;
    top: 4px;
    right: 10px;
    bottom: 0;
    width: 24px;
    margin: auto;
    transition: .5s;

    fill: #edbb49;
  }
}
