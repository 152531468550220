.catalog-one {
  padding: 20px 30px;
}
.catalog-one-top {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  @include m680() {
    flex-direction: column;
  }
}
.catalog-one-descr {
  display: flex;
  flex-direction: column;
  width: 50%;

  align-items: flex-start;
  @include m680() {
    width: 100%;
  }
}
.catalog-one-descr__header {
  border-bottom: 3px solid #19aec1;
  border-radius: 2px;
  margin-bottom: 15px;
}
.catalog-one-descr__title {
  font-size: 30px;
  line-height: 1;
  color: #006271;

  @include pt(regular);
  margin-bottom: 15px;
}
.catalog-one-descr__text {
  color: #4e4e4e;
  font-size: 14px;
  line-height: 16px;
  @include os(regular);
  margin-bottom: 15px;
}
.catalog-one-descr__btn {
  margin: 10px 0;
}

