.pagination {
  padding: 20px 0;
  text-align: center;
}
.pagination__list {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.pagination__item {
  display: inline-block;
  margin: 0 3px;
  transition: all .5s;
  &.active {
    .pagination__link {
      transition: all .5s;
      color: #fff;
      border: 1px solid #19aec1;
      background-color: #19aec1;
    }
  }
  &.notactive {
    pointer-events: none;
  }
}
.pagination__link {
  font-size: 14px;
  display: inline-block;
  padding: 3px 6px;
  transition: all .5s;
  text-align: center;
  text-decoration: none;
  color: #363636;
  border: 1px solid transparent;
  border-radius: 5px;
  &:hover {
    transition: all .5s;
    color: #363636;
      border: 1px solid #19aec1;
  }
}