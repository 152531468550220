.solutions {
  min-height: 500px;
  padding: 20px;
}
.solutions-left {
  padding-right: 300px;

  @include m768() {
    padding-right: 0;
  }
}

// ***** begin solutions-left-item
.solutions-left-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  padding: 20px 35px 60px 320px;
  transition: .5s;
  border-radius: 8px;

  @include m500() {
    padding: 20px;
  }
  &:hover {
    transition: .5s;
    box-shadow: 0 0 50px -10px rgba(0,98,113,.65);
    .solutions-left-item__title {
      transition: .5s;
      color: #19aec1;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    border: 2px solid #c2d4d6;
    border-radius: 8px;
  }
}
.solutions-left-item__pic {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px;

  @include m500() {
    display: none;
  }
  img {
    display: block;
    width: 100%;
    min-height: 100%;

    object-fit: cover;
  }
}
.solutions-left-item__title {
  font-size: 24px;
  line-height: 1.1;
  margin-bottom: 10px;
  transition: .5s;
  color: #006271;
  padding-bottom: 10px;
   border-bottom: 1px dashed #efc051;

  @include pt(regular);
}
.solutions-left-item__text {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
  color: #4e4e4e;

  @include pt(regular);
}
.solutions-left-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
// ***** end solutions-left-item