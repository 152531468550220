.catalog-meta {
  padding: 20px 20px;
}
.catalog-meta__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  @include m1024() {
    justify-content: space-around;
  }
  .catalog-meta__item,
  .catalog-right-info {
    position: relative;
    width: 270px;
    margin: 0 5px 30px 5px;
  }
}
.catalog-meta__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 260px;
  &:hover {
    & .catalog-meta__text::before {
      transform: rotate(720deg);
    }
  }
}
.catalog-meta__pic {
  overflow: hidden;
  width: 255px;
  height: 190px;
  border-radius: 5px 5px 0 0;
  img {
    display: block;
    width: 100%;
    min-height: 100%;
  }
}
.catalog-meta__text {
  position: relative;
  width: 100%;
  padding: 10px 20px 20px 20px;
  text-align: center;
  border-radius: 5px;
  background-image: linear-gradient(to top, #00889b 0%, #29b2c3 100%);

  flex: 1 0 auto;

  &::before {
    position: absolute;
    right: 0;
    bottom: -13px;
    left: 0;
    width: 30px;
    height: 30px;
    margin: auto;
    content: '';
    transition: transform .8s ease-in-out;
    border-radius: 50%;
    background: url('../img/scales-green.svg');
    background-color: #fff;
    background-size: cover;
  }
}
.catalog-meta__title {
  font-size: 16px;
  line-height: 18px;
  color: #fff;

  @include os(regular);
}
.catalog-meta__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.catalog-meta-article {
  margin-bottom: 10px;
}
.catalog-meta-article__title {
  font-size: 24px;
  line-height: 1.1;
  margin-bottom: 10px;
  color: #006271;

  @include os(light);
}
.catalog-meta-article__text {
  font-size: 14px;
  line-height: 18px;
  color: #4e4e4e;

  @include os(regular);
}
