.mini-map-sec {
  .container-lg {
    padding-top: 45px;
    padding-bottom: 25px;
  }
}
.mini-map {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  background-image: linear-gradient(to top, #00889b 0%, #29b2c3 100%);
  box-shadow: 0 0 40px -10px #19aec1;
}
.mini-map__left {
  display: inline-block;
  width: 60%;
  margin-right: -4px;
  padding: 35px 20px 20px 35px;
  background-image: linear-gradient(to top, #00889b 0%, #29b2c3 100%);

  @include m680() {
    display: block;
    width: 100%;
  }
}
.mini-map__title {
  font-size: 26px;
  margin-bottom: 20px;
  color: #fff;

  @include pt(regular);
}
.mini-map__text {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  color: #fff;

  @include pt(regular);
}
.mini-map__right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40%;

  @include m680() {
    position: static;
    display: block;
    width: 100%;
    height: 300px;
  }
}

// btn
.mini-map__btn-border {
  display: inline-block;
  margin: 10px 0;
  padding: 7px 7px 5px 7px;
  border: 1px dashed white;
  border-radius: 4px;
}
.mini-map__btn-wrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  transition: .5s;
  border-radius: 4px;
  box-shadow: 0 5px 13px 0 rgba(0,0,0,.3);
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -5px;
    left: 0;
    content: '';
    transition: .5s;
    background-color: #208ea0;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    margin: auto;
    content: '';
    background-color: #dcdcdc;
  }
  &:hover {
    transition: .5s;
    box-shadow: 0 5px 13px 0 rgba(0,0,0,.5);
    .mini-map__btn {
      transition: .5s;
      background-color: #ee7b4d;
      &::after {
        background-position: -69px 15px;
      }
      svg {
        transition: .5s;

        fill: #ef835a;
      }
    }
    &::before {
      transition: .5s;
      background-color: #eb7041;
    }
  }
}
.mini-map__btn {
  font-size: 16px;
  position: relative;
  z-index: 10;
  display: inline-block;
  overflow: hidden;
  padding: 15px 65px 10px 15px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  border-radius: 4px;
  background-color: #1ca1ba;
  text-shadow: 1px 1px 2px rgba(100, 100, 100, 1);

  @include pt(bold);
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    margin: auto;
    content: '';
    background: #f8f8f8;
  }
  svg {
    position: absolute;
    z-index: 10;
    top: 4px;
    right: 10px;
    bottom: 0;
    width: 24px;
    margin: auto;
    transition: .5s;

    fill: #208ea0;
  }
}
