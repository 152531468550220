/*********** begin #popup_form.css ***********/
.formBox {
  position: relative;
  margin-top: 80px;
  text-align: left;
  h4 {
    font-size: 18px;
    margin-bottom: 5px;
    letter-spacing: .6px;
    color: #005463;

    @include os(regular);
  }
}
.inputBox label,
.captchaBox label,
.textareaBox label,
.checkItem label {
  display: block;
  margin: 0 0 5px;
  color: #898989;
}
.inputBox input,
.captchaBox input {
  font-size: 20px;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px 15px;
  letter-spacing: .5px;
  color: #575757;
  border: 1px solid #b6b6b6;
  border-radius: 6px;
  background: #fff;
}
.inputBox input[type='text']:focus,
.inputBox input[type='text']:active,
.captchaBox input[type='text']:focus,
.captchaBox input[type='text']:active {
  border-color: #96dae8;
  border-radius: 6px;
  outline: none;
}
.inputBox.error {
  background: none;
}
.error input[type='text'] {
  border-color: #ed1b24 !important;
  border-radius: 6px;
  background: #fbd1d3 !important;
}

/* =========================== / form =========================== */

/* =========================== popup =========================== */
.layerBox {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(50,109,129,.7);
}
.feedback {
  top: -50%;
  transition: 2s;
  &.active {

  top: 50%;
  transition: 2s;
  }
}
.popupBox {
  position: fixed;
  z-index: 25000;
  left: 50%;
  // display: none;
  width: 500px;
  height: 400px;
  margin: -200px 0 0 -250px;
  color: #676767;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 48px 0 rgba(41,178,195,1);

  @include m680() {
    width: 400px;
    margin: -200px 0 0 -200px;
  }
  @include m425() {
    width: 300px;
    margin: -200px 0 0 -150px;
  }
}
.popupInn {
  padding: 20px 40px;
  text-align: center;

  @include m425() {
    padding: 20px;
  }
}

.top_block {
  position: absolute;
  top: 20px;
  right: 40px;
  left: 40px;
  text-align: left;
  h3 {
    font-size: 24px;
    position: relative;
    color: #005463;

    @include os(regular);
    @include m425() {
      font-size: 20px;
    }
    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 100px;
      height: 3px;
      margin: auto;
      content: '';
    }
  }
}

.sendBtn {
  position: relative;
  display: inline-block;
  float: right;
  margin-top: 10px;
  padding-right: 45px;
  transition: .5s;
  border-radius: 4px;
  background-color: #eee;
  box-shadow: 0 5px 13px 0 rgba(0,0,0,.3);
  &:hover {
    input {
      background-color: #ee7b4d;
    }
    svg {
      fill: #ee7b4d;
    }
  }
  input {
    font-size: 16px;
    padding: 12px 30px 10px 30px;
    transition: .5s;
    text-transform: uppercase;
    color: #fff;
    border: none;
    border-radius: 4px 0 0 4px;
    background-color: #1ca1ba;
    text-shadow: 1px 1px 2px rgba(100, 100, 100, 1);

    @include pt(bold);
  }
  svg {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 10px;
    bottom: 0;
    width: 28px;
    margin: auto;
    transition: .5s;

    fill: #208ea0;
  }
}

.success {
  height: 190px;
  margin-top: -95px;
  padding: 10px;
  text-align: center;
  top: -50%;
  transition: 2s;
  &.active {

  top: 50%;
  transition: 2s;
  }
}
.success {
  h5 {
    font-size: 24px;
    margin-bottom: 40px;
    color: #005463;

    @include os(regular);
  }
}
.success__btn-wrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  transition: .5s;
  border-radius: 4px;
  box-shadow: 0 5px 13px 0 rgba(0,0,0,.3);
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -5px;
    left: 0;
    content: '';
    transition: .5s;
    background-color: #208ea0;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    margin: auto;
    content: '';
    background-color: #eee;
  }
  &:hover {
    transition: .5s;
    box-shadow: 0 5px 13px 0 rgba(0,0,0,.5);
    .success__btn {
      transition: .5s;
      background-color: #ee7b4d;
      &::after {
        background-position: -69px 15px;
      }
      svg {
        transition: .5s;

        fill: #ef835a;
      }
    }
    &::before {
      transition: .5s;
      background-color: #eb7041;
    }
  }
}
.success__btn {
  font-size: 16px;
  position: relative;
  z-index: 10;
  display: inline-block;
  overflow: hidden;
  width: 220px;
  padding: 12px 65px 8px 15px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  border-radius: 4px;
  background-color: #1ca1ba;
  text-shadow: 1px 1px 2px rgba(100, 100, 100, 1);

  @include pt(bold);
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    margin: auto;
    content: '';
    background: #f8f8f8;
  }
  svg {
    position: absolute;
    z-index: 10;
    top: 2px;
    right: 10px;
    bottom: 0;
    width: 30px;
    margin: auto;
    transition: .5s;

    fill: #208ea0;
  }
}
/*********** end #popup_form.css ***********/
