.index-second-sec {
  .container-sm {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.index-second-sec__title {
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 20px;
  letter-spacing: .32px;
  color: #006271;

  @include os(light);
}
.index-second-sec__text {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: .16px;
  color: #5b5b5b;

  @include pt(regular);
}

.index-ss-banner {
  margin-bottom: -55px;
  padding: 30px 0 0 0;
  border: 5px solid #19aec1;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 40px -10px rgba(25,174,193,1);
}
.index-ss-banner__item {
  position: relative;
  display: inline-block;
  width: 33.3%;
  margin-right: -4px;
  margin-bottom: 30px;
  padding: 0 5px 0 80px;
  transition: .5s;
  vertical-align: top;
  @include m1000() {
    width: 50%;
  }
  @include m680() {
    width: 100%;
  }
  &:hover {
    .index-ss-banner__title {
      transition: .5s;
      color: #f0b338;
    }
    .index-ss-banner__svg {
      transition: .5s;

      fill: #f0b338;
    }
  }
}
.index-ss-banner__title {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 10px;
  transition: .5s;
  color: #006271;

  @include os(semibold);
}
.index-ss-banner__text {
  font-size: 14px;
  line-height: 16px;
  color: #4e4e4e;

  @include pt(regular);
}
.index-ss-banner__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  text-align: center;
}
.index-ss-banner__svg {
  max-width: 32px;
  max-height: 32px;
  transition: .5s;

  fill: #006271;
}
.index-ss-banner__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
