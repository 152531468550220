.mm-panels {
  background-color: #007382;
}
.mob-menu {
  .mm-navbar,
  .mob-menu__item,
  .mob-menu__subitem {
    border-color: #53bbc9;
  }
  .mm-title {
    font-size: 18px;
    transition: .5s;
    color: #fff !important;
    &:hover {
      transition: .5s;
      color: #f0b132 !important;
    }
  }
  .mob-menu__link,
  .mob-menu__sublink {
    font-size: 18px;
    transition: .5s;
    text-decoration: none;
    color: #fff;
    &:hover,
    &.active {
      transition: .5s;
      color: #f0b132;
    }
  }
  .mm-btn {
    &:hover {
      &::before {
        border-color: #f0b132 !important;
      }
    }
  }
  .mm-next {
    &:hover {
      &::after {
        border-color: #f0b132 !important;
      }
    }
  }
}
.mm-menu.mm-theme-white .mm-listview > li .mm-arrow:after,
.mm-menu.mm-theme-white .mm-listview > li .mm-next:after,
.mm-menu.mm-theme-white .mm-navbar .mm-btn:after,
.mm-menu.mm-theme-white .mm-navbar .mm-btn:before {
  border-color: #fff;
}
