.object-one {
  padding: 20px 30px;
}
.object-one-top {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  align-items: flex-start;
  @include m680() {
    flex-direction: column;
  }
}
.object-one-descr {
  display: flex;
  flex-direction: column;
  width: 50%;

  align-items: flex-start;
  @include m680() {
    width: 100%;
  }
}
.object-one-descr__title {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 15px;
  color: #006271;

  @include pt(regular);
}
.object-one-descr__adress {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  border-bottom: 1px solid #c2d4d6;

  align-items: center;
  &:hover {
    svg {
      transition: .5s;

      fill: #0ea3b6;
    }
    .object-one-descr__adress--link {
      transition: .5s;
      text-decoration: underline;
      color: #0ea3b6;
    }
  }
  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    transition: .5s;

    fill: #005463;
  }
}
.object-one-descr__adress--link {
  font-size: 14px;
  transition: .5s;
  text-decoration: none;
  color: #005463;

  @include os(regular);
}
.object-one-descr__tag-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  flex-wrap: wrap;
}
.object-one-descr__tag {
  font-size: 14px;
  margin: 15px 20px 0 0;
  padding: 8px 20px;
  color: #005463;
  border: 1px solid #9bd6de;
  border-radius: 6px;

  @include os(regular);
}

.object-one-descr__text p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
  color: #4e4e4e;

  @include pt(regular);
}

.object-one-bottom {
  margin-bottom: 10px;
  padding: 25px 30px;
  border: 1px dashed #efc051;
  border-radius: 6px;
}
.object-one-bottom__title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #006271;

  @include pt(regular);
}
.object-one-bottom__item {
  margin-bottom: 20px;
}
.object-one-bottom__item {
  h4 {
    font-size: 16px;
    color: #585858;

    @include pt(bold);
  }
  p {
    font-size: 14px;
    color: #585858;

    @include pt(regular);
  }
}
.object-one-bottom__item--title {
  font-size: 16px;
  color: #585858;

  @include pt(bold);
}
.object-one-bottom__item--text {
  font-size: 14px;
  color: #585858;

  @include pt(regular);
}

.object-one-nav {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}
.object-one-nav__prev,
.object-one-nav__next {
  position: relative;
  min-height: 40px;
  &:hover {
    .object-one-nav__prev--link,
    .object-one-nav__next--link {
      &::before {
        transition: .5s;
        background-color: #076d7a;
      }
    }
    span {
      transition: .5s;
      color: #076d7a;
    }
  }
  span {
    font-size: 14px;
    display: block;
    transition: .5s;
    text-transform: uppercase;
    color: #0ea3b6;

    @include os(bold);
  }
}
.object-one-nav__prev {
  span {
    padding: 20px 20px 20px 55px;

    @include m500() {
      display: none;
    }
  }
}
.object-one-nav__next {
  text-align: right;
  span {
    padding: 20px 55px 20px 20px;

    @include m500() {
      display: none;
    }
  }
}
.object-one-nav__prev--link,
.object-one-nav__next--link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 38px;
    height: 38px;
    margin: auto;
    content: '';
    transition: .5s;
    border-radius: 8px;
    background-color: #0ea3b6;
    background-image: url('../img/object-slider-arr.png');
    background-repeat: no-repeat;
  }
}
.object-one-nav__prev--link {
  &::before {
    left: 0;
    background-position: 12px 9px;
  }
}
.object-one-nav__next--link {
  &::before {
    right: 0;
    background-position: -64px 9px;
  }
}
