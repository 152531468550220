.newsone {
  display: flex;
  flex-direction: row;
  padding-top: 20px;

  @include m1000() {
    flex-direction: column;
  }
}

.newsone-news {
  width: 70%;

  @include m1000() {
    width: 100%;
  }
}
.newsone-news__pic {
  overflow: hidden;
  max-height: 230px;
  margin-bottom: 13px;
  border-radius: 5px;
  img {
    display: block;
    width: 100%;
  }
}
.newsone-news__title {
  font-size: 26px;
  display: block;
  margin-bottom: 3px;
  transition: .5s;
  color: #363636;
}
.newsone-news__date {
  font-size: 18px;
  position: relative;
  display: block;
  margin-bottom: 15px;
  color: #363636;

  @include pt(bold);
  &::before {
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 130px;
    height: 2px;
    content: '';
    transition: .5s;
    background-color: #19aec1;
  }
}
.newsone-news__text {
  p {
    font-size: 14px;
    line-height: 18px;
    display: block;
    margin-bottom: 10px;
    color: #363636;

    @include pt(regular);
  }
}
.newsone-news__footer {
  display: flex;
  flex-direction: row;
  padding: 5px 10px 5px 5px;
  border-top: 4px solid #19aec1;

  justify-content: space-between;
  align-items: center;
}
.newsone-news__footer-link {
  color: #006271;
  &:hover {
    text-decoration: none;
  }
}
.newsone-news__footer-sociallink {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 5px;
  transition: .5s;
  background: url('../img/newsone-social-sprite.png') no-repeat;
  &.fb {
    background-position: 0 0;
  }
  &.vk {
    background-position: 100% 0;
  }
  &:hover {
    transition: .5s;
    transform: scale(.92);
  }
}

.newsone-aside {
  float: right;
  width: 30%;
  padding-left: 20px;

  @include m1000() {
    width: 100%;
    padding-left: 0;
  }
}
.newsone-aside-item__list {
  @include m1000() {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.newsone-aside__title {
  font-size: 30px;
  margin: -5px 0 10px 0;
  color: #363636;

  @include pt(regular);
}
.newsone-aside-item {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 15px 10px 20px;
  border: 1px dashed #efc051;
  border-radius: 4px;

  @include m1000() {
    width: 32%;
  }
  @include m680() {
    width: 48%;
  }
  @include m425() {
    width: 100%;
  }
  &:hover {
    .newsone-aside-item__title {
      transition: .5s;
      color: #f0b132;
    }
    .newsone-aside-item__date {
      &::before {
        transition: .5s;
        background-color: #f0b132;
      }
    }
  }
}
.newsone-aside-item__title {
  font-size: 18px;
  margin-bottom: 5px;
  transition: .5s;
  color: #363636;

  @include pt(bold);
}

.newsone-aside-item__date {
  font-size: 12px;
  position: relative;
  display: block;
  margin-bottom: 15px;
  transition: .5s;
  color: #363636;

  @include pt(regular);
  &::before {
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 130px;
    height: 2px;
    content: '';
    transition: .5s;
    background-color: #19aec1;
  }
}
.newsone-aside-item__text {
  font-size: 12px;
  line-height: 14px;
  display: block;
  margin-bottom: 10px;
  color: #363636;

  @include pt(regular);
}
.newsone-aside-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
