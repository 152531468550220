.preloader {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #12a7ba;
}
.preloader__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%);
}
.preloader__animation {
  position: relative;
  width: 150px;
  height: 150px;
  animation: spin 2s linear infinite;
  border: 3px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  &::before,
  &::after {
    position: absolute;
    content: '';
    border: 3px solid transparent;
    border-top-color: #fff;
    border-radius: 50%;
  }
  &::before {
    top: 6px;
    right: 6px;
    bottom: 6px;
    left: 6px;
    animation: spin 1.5s linear infinite;
  }
  &::after {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    animation: spin 1s linear infinite;
  }
}
.preloader__percents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
