.contacts-banner {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 30px;
  left: 30px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px dashed #efc051;
  border-radius: 4px;
  background-color: #fff;

  justify-content: space-around;
  @include m1000() {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @include m768() {
    // flex-direction: column;
    // align-items: center;
    // justify-content: space-around;
  }
}
.contact__item {
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  @include m768() {
    margin: 5px 0;
  }
  &.border {
    width: 1px;
    background-color: #efc051;

    @include m425() {
      display: none;
    }
  }
}
.contact__item--svg {
  width: 35px;
  height: 35px;
  margin-right: 30px;

  @include m1000() {
    margin-right: 10px;
  }
  svg {
    width: 100%;
    height: 100%;

    fill: #005a69;
  }
}
.contact__item--link {
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #005a69;

  @include pt(regular);
  @include m1000() {
    font-size: 18px;
  }
}

.contacts-map {
  height: 100%;
  margin-top: 0;

  @include m768() {
    min-height: 600px;
  }
}
