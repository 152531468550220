.why-we__header {
  margin-bottom: 20px;
  text-align: center;
}
.why-we__title {
  font-size: 30px;
  position: relative;
  display: inline;
  padding-left: 35px;
  color: #363636;

  @include pt(regular);
  @include m425() {
    font-size: 26px;
  }
  &::before {
    position: absolute;
    top: 7px;
    left: 0;
    width: 25px;
    height: 25px;
    content: '';
    background: transparent url('../img/title-icon-sprite.png') 0 100% no-repeat;

    @include m425() {
      top: 3px;
    }
  }
}
.why-we__list {
  padding: 30px 10px 10px 10px;
  border: 1px dashed #efc051;
  border-radius: 4px;
}
.why-we__item {
  display: inline-block;
  width: 25%;
  margin-right: -4px;
  margin-bottom: 20px;
  text-align: center;
  vertical-align: top;

  @include m680() {
    width: 50%;
  }
}
.why-we__pic {
  margin-bottom: 10px;
  svg {
    max-width: 65px;
    max-height: 65px;

    fill: #0f273a;
  }
}
.why-we__text {
  font-size: 18px;
  color: #363636;

  @include pt(regular);
  @include m768() {
    font-size: 16px;
  }
}

.why-we__footer {
  margin-bottom: 30px;
  padding: 15px 10px 0 10px;
  text-align: center;
}
.why-we__link {
  font-size: 18px;
  line-height: 18px;
  transition: .5s;
  text-decoration: none;
  color: #006271;

  @include pt(regular);
  &:hover {
    transition: .5s;
    color: #efc051;
  }
}
