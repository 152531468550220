
.newslist-filter {
  display: flex;
  flex-direction: row;
  margin: 15px 0 20px 0;
  padding: 15px 20px 5px 20px;
  border: 1px dashed #efc051;
  border-radius: 4px;

  align-items: baseline;
  @include m1000() {
    flex-direction: column;
  }
}
.newslist-filter__title {
  font-size: 18px;
  margin: 0 50px 10px 0;
  color: #006271;

  @include os(semibold);
}
.newslist-filter__radio {
  font-size: 16px;
  display: inline-block;
  margin: 0 50px 10px 0;
  cursor: pointer;
  transition: .5s;
  color: #008ea1;
  border-bottom: 1px solid #008ea1;

  @include os(regular);
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    transition: .5s;
    border-bottom: 1px solid transparent;
  }
  &.active {
    transition: .5s;
    color: #979797;
    border-bottom: 1px solid transparent;
  }
  input {
    display: none;
  }
}

.newslist {
  display: flex;
  flex-direction: row;
  padding-top: 10px;

  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  @include m680() {
    justify-content: space-between;
  }
}
.newslist-item {
  position: relative;
  width: 30%;
  margin-right: 5%;
  margin-bottom: 20px;
  transition: .5s;

  @include m680() {
    width: 47%;
    margin-right: 0;
  }
  @include m425() {
    width: 100%;
  }
  &:nth-child(3n) {
    margin-right: 0;
  }
  &:hover {
    .newslist-item__title {
      transition: .5s;
      color: #f0b132;
    }
    .newslist-item__date {
      &::before {
        transition: .5s;
        background-color: #f0b132;
      }
    }
  }
}
.newslist-item__pic {
  overflow: hidden;
  // max-height: 230px;
  height: 230px;
  margin-bottom: 13px;
  border-radius: 5px;
  img {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}
.newslist-item__title {
  font-size: 18px;
  display: block;
  margin-bottom: 3px;
  transition: .5s;
  color: #363636;

  @include pt(bold);
}
.newslist-item__date {
  font-size: 12px;
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-left: 10px;
  color: #363636;

  @include pt(regular);
  &::before {
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 130px;
    height: 2px;
    content: '';
    transition: .5s;
    background-color: #19aec1;
  }
}
.newslist-item__text {
  font-size: 12px;
  line-height: 14px;
  display: block;
  margin-bottom: 10px;
  color: #363636;

  @include pt(regular);
}
.newslist-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
