%clearfix {
  &::before,
  &::after {
    content: "";
    display: table;
  }
  &::after {
    clear: both;
  }
}

@mixin os($style : regular) {
  @if $style == regular {
    font-family: 'OpenSans', Arial, sans-serif;
  }
  @if $style == light {
    font-family: 'OpenSans-Light', Arial, sans-serif;
  }
  @if $style == bold {
    font-family: 'OpenSans-Bold', Arial, sans-serif;
  }
  @if $style == semibold {
    font-family: 'OpenSans-Semibold', Arial, sans-serif;
  }
}

@mixin pt($style : regular) {
  @if $style == regular {
    font-family: 'PTSans-Regular', Arial, sans-serif;
  }
  @if $style == bold {
    font-family: 'PTSans-Bold', Arial, sans-serif;
  }
}

// ===== responsive =====
//desktop
@mixin m1200 {
  @media screen and (max-width: 1200px) {
    @content;
  }
}
//desktop
@mixin m1100 {
  @media screen and (max-width: 1100px) {
    @content;
  }
}
@mixin m1024 {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin m1000 {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
//laptop
@mixin m992 {
  @media screen and (max-width: 992px) {
    @content;
  }
}
//tablets
@mixin m768 {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin m680 {
  @media screen and (max-width: 680px) {
    @content;
  }
}
@mixin m600 {
  @media screen and (max-width: 600px) {
    @content;
  }
}
@mixin m500 {
  @media screen and (max-width: 500px) {
    @content;
  }
}
//phones
@mixin m425 {
  @media screen and (max-width: 425px) {
    @content;
  }
}
//phones
@mixin m400 {
  @media screen and (max-width: 400px) {
    @content;
  }
}

// ===== mobile-first =====
@mixin mf1200 {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
@mixin mf1000 {
  @media screen and (min-width: 1000px) {
    @content;
  }
}
@mixin mf1100 {
  @media screen and (min-width: 1100px) {
    @content;
  }
}
@mixin mf975 {
  @media screen and (min-width: 975px) {
    @content;
  }
}
@mixin mf768 {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin mf715 {
  @media screen and (min-width: 715px) {
    @content;
  }
}
@mixin mf680 {
  @media screen and (min-width: 680px) {
    @content;
  }
}
@mixin mf560 {
  @media screen and (min-width: 560px) {
    @content;
  }
}
@mixin mf475 {
  @media screen and (min-width: 475px) {
    @content;
  }
}
@mixin mf320 {
  @media screen and (min-width: 320px) {
    @content;
  }
}

// ===== placeholder color =====
@mixin placecolor($color: #5f5f5f) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}


// ===== calculate to Rem =====
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin line-height($size) {
  line-height: $size;
  line-height: calculateRem($size);
}