.inner {
  padding-top: 20px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 10px 0;
  }
  h1 {
    font-size: 36px;
    color: #505050;

    @include os(light);
  }
  h2 {
    font-size: 24px;
    color: #006271;

    @include os(light);
  }
  h3 {
    font-size: 24px;
    color: #006271;

    @include os(regular);
  }
  h4 {
    font-size: 22px;
    color: #f0b132;

    @include os(regular);
  }
  h5 {
    font-size: 18px;
    text-transform: uppercase;
    color: #137182;

    @include pt(bold);
  }
  h6 {
    font-size: 14px;
    text-transform: uppercase;
    color: #16abbe;

    @include pt(bold);
  }
  p {
    font-size: 14px;
    line-height: 20px;

    @include os(regular);
  }
  a {
    transition: .5s;
    color: #f6882b;
    &:hover {
      transition: .5s;
      text-decoration: none;
      color: #f6882b;
    }
  }
  ul li,
  ol li {
    font-size: 14px;
    line-height: 20px;
  }
  ul li {
    position: relative;
    list-style: none;
    color: #4e4e4e;

    @include pt(regular);
    &::before {
      position: absolute;
      top: 6px;
      left: -15px;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      border-radius: 50%;
      background-color: #16abbe;
    }
  }
  ol li {
    @include os(regular);
  }
}
