.header-left,
.header-right {
  display: inline-block;
  margin-right: -4px;
  vertical-align: middle;
}
.header-left {
  width: 55%;
  // background-color: #ccc;

  @include m1000() {
    width: 34%;
    text-align: center;
  }
  @include m680() {
    width: 50%;
  }
  @include m500() {
    width: 100%;
  }
}
.header-right {
  width: 45%;
  text-align: right;

  @include m1000() {
    width: 66%;
  }
  @include m680() {
    width: 50%;
    text-align: center;
  }
  @include m500() {
    width: 100%;
  }
}

.header-logo,
.header-logo__title,
.header-btn,
.header-contacts {
  display: inline-block;
  padding: 15px 0;
  vertical-align: middle;
}

.header-logo {
  padding-right: 15px;

  @include m500() {
    height: 80px;
  }
  img {
    width: 100%;
    max-width: 240px;
    height: 65px;
  }
}
.header-logo__title {
  font-size: 14px;
  line-height: 16px;
  position: relative;
  padding-left: 15px;
  color: #00879a;

  @include pt(regular)
  @include m1000() {
    display: none;
  }
  &::before {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 0;
    width: 1px;
    content: '';
    background-color: #00879a;
  }
}
.header-btn {
  @include m680() {
    margin-bottom: -20px;
  }
  @include m500() {
    width: 100%;
  }
}
.header-contacts {
  padding-left: 15px;

  @include m680() {
    padding-left: 0;
  }
  @include m500() {
    width: 100%;
  }
}
.header-contacts__phone {
  font-size: 22px;
  line-height: 28px;
  transition: .5s;
  text-decoration: none;
  letter-spacing: .22px;
  color: #005a69;
  border-bottom: 1px solid transparent;

  @include pt(regular);
  @include m500() {
    font-size: 18px;
    line-height: 22px;
  }
  &:hover {
    transition: .5s;
    border-bottom: 1px solid #005a69;
  }
}
.header-contacts__mail {
  font-size: 18px;
  line-height: 28px;
  position: relative;
  display: inline-block;
  transition: .5s;
  text-decoration: none;
  letter-spacing: .18px;
  color: #005a69;
  border-bottom: 1px solid transparent;

  @include pt(regular);
  @include m680 {
    display: none;
  }
  &:hover {
    transition: .5s;
    border-bottom: 1px solid #005a69;
  }
  svg {
    position: absolute;
    top: 2px;
    bottom: 0;
    left: -30px;
    width: 24px;
    margin: auto;

    fill: #005a69;
  }
}
.header-bottom {
  position: relative;
  min-height: 35px;
  background-image: url('../img/bg-green.jpg');
  &.sticking {
    z-index: 10000;
    right: 0;
    left: 0;
  }
}
.header-bottom__stick {
  &.sticking {
    z-index: 10000;
    right: 0;
    left: 0;
    height: 40px;
    background-image: url('../img/bg-green.jpg');
  }
}

//page header elements
.header-bottom__info {
  display: flex;
  flex-direction: row;
  padding-top: 60px;
  padding-bottom: 15px;

  justify-content: space-between;
  align-items: center;
  @include m768() {
    flex-direction: column;

    align-items: flex-start;
  }
}
.header-bottom__title {
  min-width: 50%;
}
.page-title {
  font-size: 30px;
  display: inline-block;
  color: #fff;

  @include os(light);
}

.breadcrumbs__list {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: flex-end;

  .breadcrumbs__item:last-child {
    .breadcrumbs__link {
      text-decoration: none;
      pointer-events: none;
    }
  }
}
.breadcrumbs__item {
  list-style: none;
  &::after {
    display: inline-block;
    width: 1px;
    height: 20px;
    margin: 0 10px 0 7px;
    content: '/';
    color: #fff;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}
.breadcrumbs__link {
  line-height: 24px;
  color: #fff;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
  }
}
