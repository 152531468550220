.error-wrapper {
  .footer {
    margin-top: -10px;
  }
}
.error {
  background: url('../img/bg-green.jpg');
}
.error-banner {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 50px;
  text-align: center;
}
.error-banner__top {
  font-size: 48px;
  margin: 100px auto 70px;
  color: #fff;

  @include os(light);
  @include m1024() {
    margin: 50px auto;
  }
  @include m768() {
    font-size: 38px;
    margin: 30px auto;
  }
}

.error-banner__404 {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  justify-content: center;
  align-items: flex-start;
}
.error-banner__404-left,
.error-banner__404-right {
  font-size: 116px;
  line-height: 116px;
  margin-top: -15px;
  color: #fff;

  @include os(light);
}
.error-banner__404-center {
  width: 100px;
  margin: 0 10px;
  svg {
    fill: #fff;
  }
}
.error-banner__404-center--top {
  svg {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
    width: 85px;
    height: 85px;
  }
}
.error-banner__404-center--bottom {
  margin-top: -10px;
  svg {
    width: 50px;
    height: 45px;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-banner__bottom {
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;

  @include pt(regular);
  @include m768() {
    font-size: 20px;
  }
}
.error-banner__link {
  font-size: 18px;
  text-decoration: underline;
  color: #fff;

  @include os(regular);
  @include m768() {
    font-size: 16px;
  }
  &:hover {
    text-decoration: none;
  }
}
