.map-page {
  padding: 20px;
}

// ***** begin filter
.map-filter {
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  border: 1px dashed #efc051;
  border-radius: 4px;

  @include m1000() {
    flex-direction: column;
    padding: 5px 25px;
  }
}
.map-filter__item {
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-right: 20px;

  justify-content: center;
  align-items: center;
  @include m1000() {
    width: 100%;
    margin: 10px 0;
  }
  @include m500() {
    flex-direction: column;

    align-items: flex-start;
  }
}
.map-filter__title {
  font-size: 18px;
  margin-right: 15px;
  color: #004c5b;

  @include os(bold);
  @include m1000() {
    min-width: 150px;
  }
  @include m500() {
    margin-bottom: 10px;
  }
}
// ***** end filter
.map {
  height: 100%;
  margin-top: 0px;
  border-top: 4px solid #099fb1;
}