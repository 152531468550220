.catalog {
  min-height: 600px;
  padding: 20px;
}
.catalog-left {
  padding-right: 300px;

  @include m768() {
    padding-right: 0;
  }
}

// ***** begin filter
.catalog-filter {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 15px 25px;
  border: 1px dashed #efc051;
  border-radius: 4px;

  @include m1000() {
    flex-direction: column;
    padding: 5px 25px;
  }
}
.catalog-filter__item {
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-right: 20px;

  align-items: center;
  @include m1000() {
    width: 100%;
    margin: 10px 0;
  }
  @include m500() {
    flex-direction: column;

    align-items: flex-start;
  }
}
.catalog-filter__title {
  font-size: 18px;
  margin-right: 15px;
  color: #004c5b;

  @include os(bold);
  @include m1000() {
    min-width: 150px;
  }
  @include m500() {
    margin-bottom: 10px;
  }
}
.ui-button .ui-icon {
  width: 0;
  height: 0;
  margin-top: 7px;
  border-top: 10px solid #19aec1;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  background: transparent;
}
.ui-selectmenu-button.ui-button {
  width: 100%;
  max-width: 250px;
  padding: 5px 15px;
  border-color: #005a69;
  border-radius: 5px;
  outline: none;
  background: transparent url('../img/bg-white.jpg') 0 0;
}
.ui-selectmenu-button.ui-selectmenu-button-open {
  border-radius: 5px 5px 0 0;
}
.ui-selectmenu-text {
  font-size: 16px;
  color: #004c5b;

  @include os(regular);
}
.ui-widget.ui-widget-content {
  font-size: 16px;
  color: #004c5b;
  border-color: #005a69;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background: transparent url('../img/bg-white.jpg') 0 0;

  @include os(regular);
}
.ui-menu-item-wrapper {
  padding: 8px 10px 8px 15px !important;
}
.ui-menu-item-wrapper.ui-state-active {
  color: #f68728;
  border-color: #f3f3f3;
  background-color: #f3f3f3;
}
// ***** end filter

.catalog-left__header {
  margin-bottom: 15px;
  padding: 0 20px 10px 20px;
  border-bottom: 3px solid #19aec1;
  border-radius: 2px;
}
.catalog-left__header--hide {
  position: relative;
  padding: 7px 20px 10px 40px;
  cursor: pointer;
  @include m425() {
    padding: 7px 20px 10px 30px;
  }
  &::before,
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
    transition: .1s;
    pointer-events: none;
    border-radius: 3px;
    background-color: #19aec1;
  }
  &::before {
    left: 0px;
    width: 30px;
    height: 6px;

    @include m425() {
      width: 20px;
      height: 4px;
    }
  }
  &::after {
    left: 12px;
    width: 6px;
    height: 30px;
    opacity: 1;
    transition: .3s;

    @include m425() {
      left: 8px;
      width: 4px;
      height: 20px;
    }
  }
  &.is-opened {
    &::after {
      opacity: 0;
    }
  }
}
.catalog-left__title {
  font-size: 30px;
  color: #363636;

  @include pt(regular);
}
.catalog-left__list--hide{
  display: none;
}

// ***** begin catalog-left-item
.catalog-left-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  padding: 20px 20px 20px 320px;
  transition: .5s;
  border-radius: 8px;

  @include m500() {
    padding: 20px;
  }
  &:hover {
    transition: .5s;
    box-shadow: 0 0 50px -10px rgba(0,98,113,.65);
    .catalog-left-item__title {
      transition: .5s;
      color: #19aec1;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    bottom: 0;
    left: 0;
    content: '';
    border: 2px solid #c2d4d6;
    border-radius: 8px;
  }
}
.catalog-left-item__pic {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  left: 0;
  width: 300px;

  @include m500() {
    display: none;
  }
  img {
    display: block;
    width: 100%;
    min-height: 100%;

    object-fit: cover;
  }
}
.catalog-left-item__title {
  font-size: 24px;
  line-height: 1.1;
  margin-bottom: 10px;
  transition: .5s;
  color: #006271;

  @include pt(regular);
}
.catalog-left-item__text {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
  color: #4e4e4e;

  @include pt(regular);
}
.catalog-left-item__pdf {
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 48%;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 8px 0 8px 40px;
  color: #005463;

  @include os(regular);
  @include m425() {
    width: 100%;
  }
  &:hover {
    text-decoration: none;
  }
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 26px;
    height: 32px;
    margin: auto;
    content: '';
    background: transparent url('../img/pdf-icon.png') 0 0 no-repeat;
  }
}
.catalog-left-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.catalog-left-item__tag-wrap {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  border-top: 1px solid #c2d4d6;

  flex-wrap: wrap;
}
.catalog-left-item__tag {
  font-size: 14px;
  margin: 15px 20px 0 0;
  padding: 8px 20px;
  color: #005463;
  border: 1px solid #9bd6de;
  border-radius: 6px;

  @include os(regular);
}

// ***** end catalog-left-item

.catalog-right {
  position: absolute;
  top: 20px;
  right: 0;
  width: 290px;
  padding: 0 20px 0 0;

  @include m768() {
    position: static;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;

    justify-content: space-around;
    flex-wrap: wrap;
  }
  @include m500() {
    padding: 0;
  }
}
.catalog-right-info {
  display: inline-block;
  width: 270px;
  margin: 0 0 20px 0;
  padding: 10px 20px 5px 20px;
  border: 1px dashed #efc051;
  border-radius: 4px;
}
.catalog-right-info__icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-bottom: 15px;
  vertical-align: middle;
  svg {
    width: 100%;
    height: 100%;

    fill: #208ea0;
  }
}
.catalog-right-info__title {
  font-size: 18px;
  display: inline-block;
  margin-bottom: 15px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #017c93;

  @include pt(bold);
}
// btn
.catalog-right__btn-wrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 15px;
  transition: .5s;
  border-radius: 4px;
  box-shadow: 0 5px 13px 0 rgba(0,0,0,.3);
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -5px;
    left: 0;
    content: '';
    transition: .5s;
    background-color: #208ea0;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    margin: auto;
    content: '';
    background-color: #dcdcdc;
  }
  &:hover {
    transition: .5s;
    box-shadow: 0 5px 13px 0 rgba(0,0,0,.5);
    .catalog-right__btn {
      transition: .5s;
      background-color: #ee7b4d;
      &::after {
        background-position: -69px 15px;
      }
      svg {
        transition: .5s;

        fill: #ef835a;
      }
    }
    &::before {
      transition: .5s;
      background-color: #eb7041;
    }
  }
}
.catalog-right__btn {
  font-size: 16px;
  position: relative;
  z-index: 10;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding: 15px 65px 10px 35px;
  transition: .5s;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  border-radius: 4px;
  background-color: #1ca1ba;
  text-shadow: 1px 1px 2px rgba(100, 100, 100, 1);

  @include pt(bold);
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 45px;
    margin: auto;
    content: '';
    background: #f8f8f8;
  }
  svg {
    position: absolute;
    z-index: 10;
    top: 4px;
    right: 10px;
    bottom: 0;
    width: 24px;
    margin: auto;
    transition: .5s;

    fill: #208ea0;
  }
}

//youtube
.catalog-right-youtube {
  position: relative;
  overflow: hidden;
  width: 270px;
  height: 290px;
  padding-top: 160px;
  text-align: center;
  border-radius: 8px;
  background: url('../img/bg-youtube.jpg') 0 0/cover;
  &:hover {
    .small {
      text-decoration: none;
    }
  }
}
.catalog-right-youtube__title {
  .white,
  .blue {
    font-size: 26px;
    line-height: 1.1;
    display: inline-block;
    text-transform: uppercase;

    @include pt(bold);
  }
  .white {
    color: #fff;
  }
  .blue {
    margin-bottom: 25px;
    color: #96dae8;
  }
  .small {
    font-size: 18px;
    text-decoration: underline;
    color: #fff;

    @include pt(regular);
  }
}
.catalog-right-youtube__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
