.objects {
  min-height: 600px;
  padding: 20px;
  .catalog-filter {
    margin-bottom: 30px;
  }
  .pagination {
    padding: 0;
    margin-bottom: -15px;
    @include m768() {
      margin-bottom: -5px;
    }
    @include m500() {
      margin-bottom: 15px;
    }
  }
}