.menu-wrap {
  position: relative;
}
//==============================odinokun===========================================================
.main-menu {
  position: absolute;
  z-index: 9999;
  top: -7px;
  right: 0;
  left: 0;
  margin: auto;
  padding: 0 5px;
  border-radius: 5px;
  background-image: linear-gradient(to top, #00889b 0%, #29b2c3 100%);
  .menu__list {
    margin: 0;
  }
  .menu__item {
    position: relative;
    background-image: linear-gradient(to top, #00889b 0%, #29b2c3 100%);
    &::after {
      position: absolute;
      top: 10px;
      right: 0;
      bottom: 10px;
      width: 1px;
      margin: auto;
      content: '';
      background-color: #51b9c7;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &:hover,
    &.sfHover {
      background-image: linear-gradient(to top, #00889b 0%, #29b2c3 100%);
    }
  }
  .menu__item--parent {
    position: relative;
    padding-right: 15px;
    &::before {
      position: absolute;
      top: 3px;
      right: 11px;
      bottom: 0;
      width: 10px;
      height: 6px;
      margin: auto;
      content: '';
      background: transparent url('../img/main-menu-arr.png') 0 0 no-repeat;
    }
    &:hover {
      background: #007382;
      .menu__link {
        color: #19aec1;
      }
      &::before {
        background: transparent url('../img/main-menu-arr.png') 100% 0 no-repeat;
      }
    }
  }
  .menu__subitem {
    background-color: #007382;
    &:hover {
      background-color: #007382;
    }
  }
  .menu__link,
  .menu__sublink {
    font-size: 18px;
    transition: .5s;
    text-decoration: none;
    color: #fff;
    border: none;

    @include pt(regular);
    &:hover,
    &.active {
      transition: .5s;
      color: #f0b132;
    }
  }
}
//==================================/odinokun=======================================================
.sf-menu {
  position: static;
  float: none;
  li {
    position: static;
    white-space: normal;
  }
  > li {
    > ul {
      left: auto;
      width: 220px;
      li {
        .sf-with-ul::after {
          font-family: 'FontAwesome';
          position: absolute;
          right: 1em;
          content: '';
        }
      }
    }
  }
}

//Media Queries
@media (min-width: 1024px) {
  .toggle-mnu,
  #menu,
  html.mm-blocking #mm-blocker {
    display: none;
  }
  html.mm-opening .mm-slideout {
    transform: translate(0px, 0px);
  }
}

@media (max-width: 1023px) {
  .toggle-mnu {
    display: block;
    z-index: 10;
  }
  .sf-menu {
    display: none;
  }
}

//Custom styles
.clearfix::after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '.';
}
.mm-menu.mm-effect-menu-slide {
  z-index: 9999;
}
.mm-page.mm-slideout {
  height: 100%;
}
.menu-wrap {
  margin: 0;
}
